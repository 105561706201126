// YouTube API will call this function when loaded
window.onYouTubeIframeAPIReady = function() {
  document.querySelectorAll('iframe[id^="player"]').forEach(iframe => {
    let playerId = iframe.id;
    window.players[playerId] = new YT.Player(playerId, {
      events: {
        'onReady': () => onPlayerReady(playerId),
        'onStateChange': (event) => onPlayerStateChange(event, playerId)
      }
    });
  });
};

// Store player states globally
window.players = {};
window.isPlayerReady = {};
window.isPlaying = {};
window.hasPlayed = {};

// Function called when player is ready
function onPlayerReady(playerId) {
  window.isPlayerReady[playerId] = true;
}

// Function called when player state changes
function onPlayerStateChange(event, playerId) {
  if (event.data === YT.PlayerState.PLAYING) {
    window.isPlaying[playerId] = true;
    window.hasPlayed[playerId] = true;
    document.querySelector(`[data-player="${playerId}"]`).classList.add('fade-out');
  } else if (event.data === YT.PlayerState.PAUSED || event.data === YT.PlayerState.ENDED) {
    window.isPlaying[playerId] = false;
  }
}

// Initialize click event listeners for thumbnails
function initYouTubeShortPlayers() {
  // Add function to click the thumbnail to play the video
  document.querySelectorAll('.image-wrapper').forEach(thumbnail => {
    thumbnail.addEventListener('click', function() {
      let playerId = this.dataset.player;
      if (window.isPlayerReady[playerId] && !window.hasPlayed[playerId]) {
        try {
          window.players[playerId].playVideo();
        } catch (error) {
          console.error(`Error playing ${playerId}:`, error);
        }
      }
    });
  });
}

// Load YouTube API script
function loadYouTubeAPI() {
  const tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

// Execute when DOM is loaded
document.addEventListener('DOMContentLoaded', function() {
  loadYouTubeAPI();
  initYouTubeShortPlayers();
});

export default {
  loadYouTubeAPI,
  initYouTubeShortPlayers
};
